export const GalleryData = [
  {
    id: 1,
    image: "assets/images/gallery/Park 1.jpg",
    titile: "Park",
    caption: "Park",
  },
  {
    id: 2,
    image: "assets/images/gallery/Park 2.jpg",
    titile: "Park",
    caption: "Park",
  },
  {
    id: 3,
    image: "assets/images/gallery/Park 3.jpg",
    titile: "Park",
    caption: "Park",
  },
  {
    id: 4,
    image: "assets/images/gallery/Pooja 2.jpg",
    titile: "Pooja",
    caption: "Pooja",
  },
  {
    id: 5,
    image: "assets/images/gallery/Pooja 1.jpg",
    titile: "Pooja",
    caption: "Pooja",
  },
  {
    id: 6,
    image: "assets/images/gallery/Pooja 3.jpg",
    titile: "Pooja",
    caption: "Pooja",
  },
  {
    id: 7,
    image: "assets/images/gallery/Karwachowth.jpg",
    titile: "Karwachowth",
    caption: "Karwachowth",
  },
  {
    id: 8,
    image: "assets/images/gallery/Karwachowth_1.jpg",
    titile: "Karwachowth",
    caption: "Karwachowth",
  },
  {
    id: 9,
    image: "assets/images/gallery/Gym_Opening.jpg",
    titile: "Gym_Opening",
    caption: "Gym_Opening",
  }
]