import React from 'react';
import { Link } from 'react-router-dom';
export default function HeaderComponent(){



return(
<footer className="main-footer">
  <div className="widget-section">
    <div className="auto-container">
      <div className="row clearfix">
        <div className="col-lg-4 col-md-6 col-sm-12 footer-column">
          <div className="footer-widget links-widget">
            <div className="widget-title">
              <h3>Quick Links</h3>
            </div>
            <div className="widget-content">
                
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 footer-column">
          <div className="footer-widget links-widget">
            <div className="widget-title">
              <h3>Service Overview</h3>
            </div>
            <div className="widget-content">
              <ul className="links-list clearfix">
                <li>
                  <Link to="/">24X7 Security with CCTV Surveillance</Link>
                </li>
                <li>
                  <Link to="/">Improved Water Supply</Link>
                </li>
                <li>
                  <Link to="/">Concrete Roads</Link>
                </li>
                <li>
                  <Link to="/">Rain Water Harvesting System</Link>
                </li>
                <li>
                  <Link to="/">
                    Composting Machine for Wet Waste Management
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    2 nos. 140 KVA Diesel Generator for Power Breakup
                  </Link>
                </li>
                <li>
                  <Link to="/">Common Area and Garden Maintenance</Link>
                </li>
                <li>
                  <Link to="/">
                    Guest Toilets &amp; Domestic Help Toilets for both Gents
                    &amp; Ladies
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 footer-column">
          <figure className="footer-logo">
            <Link to="/">
              <img src="assets/images/logo.png" alt="" />
            </Link>
          </figure>
          <div className="footer-widget contact-widget">
            <div className="widget-title">
              <h3>Contact Info</h3>
            </div>
            <div className="widget-content">
              <ul className="info clearfix">
                <li>
                  <h5>Location</h5>
                  <p>
                    Technology CGHS Ltd.
                    <br />
                    24, I P extension (Near West Vinod <br />
                    Nagar/Mandawali Metro Station),
                    <br /> Patpar Ganj,Delhi - 110092.
                  </p>
                </li>
                <li>
                  <h5>Phone</h5>
                  <p>
                    <Link to="tel:911147243535">+91 11 47243535</Link>
                  </p>
                </li>
                <li>
                  <h5>Email</h5>
                  <p>
                    <Link to="mailto:tcghsltd@gmail.com">tcghsltd@gmail.com</Link>
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="footer-bottom">
    <div className="auto-container">
      <div className="bottom-inner clearfix">
        <div className="copyright pull-left">
          <p>
            © 2023-24 By <Link to="/">TCGHSLTD</Link> All Rights Reserved.{" "}
          </p>
        </div>
        <ul className="footer-nav clearfix pull-right">
          <li>
            <Link to="/">FAQ’s</Link>
          </li>
          <li>
            <Link to="https://www.mygov.in/covid-19/" target="_blank">Covid’19 Updates </Link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</footer>

)
}