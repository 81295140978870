import React from "react";
import { Link, NavLink } from "react-router-dom";

export default function HeaderComponent() {
  return (
    <>
      {/* sidebar cart item */}
      <div className="xs-sidebar-group info-group info-sidebar">
        <div className="xs-overlay xs-bg-black" />
        <div className="xs-sidebar-widget">
          <div className="sidebar-widget-container">
            <div className="widget-heading">
              {/* <Link to="#" className="close-side-widget">
                <i className="fal fa-times" />
              </Link> */}
            </div>
            <div className="sidebar-textwidget">
              <div className="sidebar-info-contents">
                <div className="content-inner">
                  <div className="logo">
                    <Link to="/">
                      <img src="assets/images/logo-2.png" alt="" />
                    </Link>
                  </div>
                  <div className="content-box">
                    <h4>Book Now</h4>
                    <form
                      action="/"
                      method="post"
                      className="booking-form"
                    >
                      <div className="form-group">
                        <input
                          type="text"
                          name="name"
                          placeholder="Name"
                          required=""
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="email"
                          name="email"
                          placeholder="Email"
                          required=""
                        />
                      </div>
                      <div className="form-group">
                        <textarea
                          name="message"
                          placeholder="Text"
                          defaultValue={""}
                        />
                      </div>
                      <div className="form-group message-btn">
                        <button type="submit">Send Now</button>
                      </div>
                    </form>
                  </div>
                  <div className="contact-info">
                    <h4>Contact Info</h4>
                    <ul>
                      <li>24, I P extension (Near West Vinod <br />
                    Nagar/Mandawali Metro Station),
                    <br /> Patpar Ganj,Delhi - 110092.</li>
                      <li>
                        <Link to="tel:+911147243535">+91 11 47243535</Link>
                      </li>
                      <li>
                        <Link to="mailto:tcghsltd@gmail.com">tcghsltd@gmail.com</Link>
                      </li>
                    </ul>
                  </div>
                  <ul className="social-box">
                    <li className="facebook">
                      <Link to="#" className="fab fa-facebook-f" />
                    </li>
                    <li className="twitter">
                      <Link to="#" className="fab fa-twitter" />
                    </li>
                    <li className="linkedin">
                      <Link to="#" className="fab fa-linkedin-in" />
                    </li>
                    <li className="instagram">
                      <Link to="#" className="fab fa-instagram" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* END sidebar widget item */}
      <header className="main-header style-one">
        {/* header-top */}
        <div className="header-top">
          <div className="auto-container">
            <div className="top-inner clearfix">
              <div className="left-column pull-left clearfix">
                {/* <div className="weathre-box">
                  <i className="flaticon-sunny-day-or-sun-weather" />
                  <Link to="/">25th Jan: 32 0C / 65 0F</Link>
                </div> */}
                <ul className="links-box clearfix">
                  <li>
                  Technology Group Housing Society (Regn No. 515 G/H)
                  </li>
                </ul>
              </div>
              <div className="right-column pull-right clearfix">
                <ul className="info-list clearfix">
                  <li>
                    <i className="flaticon-phone-with-wire" />
                    <Link to="tel:4488812345">+91 11 47243535</Link>
                  </li>
                  {/* <li>
                    <i className="flaticon-fast" />
                    <Link to="/">Today: 09.00 to 18.45</Link>
                  </li> */}
                </ul>
                <ul className="social-links clearfix">
                  <li>
                    <Link to="/">
                      <i className="fab fa-facebook-square" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <i className="fab fa-twitter-square" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <i className="fab fa-youtube-square" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <i className="fab fa-linkedin" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* header-lower */}
        <div className="header-lower">
          <div className="auto-container">
            <div className="outer-box clearfix">
              <div className="logo-box pull-left">
                <figure className="logo">
                  <Link to="/">
                    <img src="assets/images/logo.png" alt=""/>
                  </Link>
                </figure>
              </div>
              <div className="menu-area clearfix pull-right">
                {/*Mobile Navigation Toggler*/}
                <div className="mobile-nav-toggler">
                  <i className="icon-bar" />
                  <i className="icon-bar" />
                  <i className="icon-bar" />
                </div>
                <nav className="main-menu navbar-expand-md navbar-light">
                  <div
                    className="collapse navbar-collapse show clearfix"
                    id="navbarSupportedContent"
                  >
                    <ul className="navigation clearfix">
                      <li className="current ">
                        <NavLink className="navbar-link" to="/">
                          Home
                        </NavLink>
                      </li>
                      <li className="dropdown">
                        <NavLink className="navbar-link" to="/">
                          About
                        </NavLink>
                        <ul>
                          <li>
                            <NavLink className="navbar-link" to="/aboutus">
                              About Us
                            </NavLink>
                          </li>
                          <li>
                            <NavLink className="navbar-link" to="/mcrwa">
                              MC(RWA)
                            </NavLink>
                          </li>
                          <li>
                            <NavLink className="navbar-link" to="/imp-contact">
                            Imp Contacts
                            </NavLink>
                          </li>
                          <li>
                            <NavLink className="navbar-link" to="/gallery">
                              Gallery
                            </NavLink>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <NavLink className="navbar-link" to="download-page">
                          Downloads
                        </NavLink>
                      </li>
                      <li>
                        <NavLink className="navbar-link" to="document-page">
                          Documents
                        </NavLink>
                      </li>
                      {/* <li>
                        <NavLink className="navbar-link" to="faq-page">
                          FAQ's
                        </NavLink>
                      </li> */}
                      <li>
                        <NavLink className="navbar-link" to="event-page">
                          Notice
                        </NavLink>
                      </li>
                      <li>
                        <NavLink className="navbar-link" to="/contact">
                          Contact
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </nav>
                {/* <div className="menu-right-content clearfix">
                  <ul className="other-option clearfix">
                    <li className="search-btn">
                      <button type="button" className="search-toggler">
                        <i className="far fa-search" />
                      </button>
                    </li>
                    <li className="nav-box">
                      <div className="nav-toggler navSidebar-button">
                        <i className="fas fa-th-large" />
                      </div>
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        {/*sticky Header*/}
        <div className="sticky-header">
          <div className="auto-container">
            <div className="outer-box clearfix">
              <div className="logo-box pull-left">
                <figure className="logo">
                  <Link to="/Homepage">
                    <img src="assets/images/logo.png" alt="" />
                  </Link>
                </figure>
              </div>
              <div className="menu-area clearfix pull-right">
                <nav className="main-menu clearfix">
                  {/*Keep This Empty / Menu will come through Javascript*/}
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* Mobile Menu  */}
      <div className="mobile-menu">
        <div className="menu-backdrop" />
        <div className="close-btn">
          <i className="fas fa-times" />
        </div>
        <nav className="menu-box">
          <div className="nav-logo">
            <Link to="/">
              <img src="assets/images/logo-2.png" alt="" title="" />
            </Link>
          </div>
          <div className="menu-outer">
            {/*Here Menu Will Come Automatically Via Javascript / Same Menu as in Header*/}
          </div>
          <div className="social-links">
            <ul className="clearfix">
              <li>
                <Link to="/">
                  <span className="fab fa-twitter" />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <span className="fab fa-facebook-square" />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <span className="fab fa-pinterest-p" />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <span className="fab fa-instagram" />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <span className="fab fa-youtube" />
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
      {/* End Mobile Menu */}
    </>
  );
}
