const ApiCalling = async (url, type, data = "") => {
    url = process.env.REACT_APP_API_PREFIX + url
    if (type === "POST") {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                // Authorization: `Basic ${process.env.REACT_APP_API_KEY}`,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });

        return response.json();
    } else {
        const response = await fetch(url, {
            method: "GET",
            headers: {
                // Authorization: `Basic ${process.env.REACT_APP_API_KEY}`,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        return response.json();
    }
};

// const explodeDataWithRowTable = (data, setApiData, returnId = false) => {
//     const rowsArray = [];
//     const valuesArray = [];
//     if (data.length > 0) {
//         // setApiData(data.items);
//         data.map((d) => {
//             if (!returnId) {
//                 if (d?.id) { delete d.id }
//             }
//             rowsArray.push(Object.keys(d));
//             valuesArray.push(Object.values(d));
//             return "";
//         });

//         const tableHeader = rowsArray[0].map((row) => ({
//             field: row,
//             // headerName: row,
//             headerName: row.toUpperCase(),
//         }));
//         const temp = {
//             row: tableHeader,
//             dataWithRows: data,
//             onlyData: valuesArray,
//         };

//         setApiData(temp);
//     } else {
//         setApiData({
//             row: [],
//             dataWithRows: [],
//             onlyData: [],
//         });
//     }

// };
export default ApiCalling;
// export { explodeDataWithRowTable };