import React, { useEffect, useRef } from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';
import Slide from './Slide';

const slideImages = [
  {
    image: "/assets/images/banner/banner-1.jpg",
    title: "Probably the Best Society in the Delhi-NCR.",
    subtitle: "TCGHSLTD",
    content: "May your home always be too small to hold all of your friends."
  },
  {
    image: "/assets/images/banner/banner-2.jpg",
    title: "Probably the Best Society in the Delhi-NCR.",
    subtitle: "TCGHSLTD",
    content: "May your home always be too small to hold all of your friends."
  },
  {
    image: "/assets/images/banner/banner-3.jpg",
    title: "Probably the Best Society in the Delhi-NCR.",
    subtitle: "TCGHSLTD",
    content: "May your home always be too small to hold all of your friends."
  },
]

const Carousel = () => {
  const carouselRef = useRef(null);

  useEffect(() => {
    if (carouselRef.current) {
      window.jQuery(carouselRef.current).owlCarousel({
        loop: true,
        margin: 0,
        nav: true,
        animateOut: 'fadeOut',
        animateIn: 'fadeIn',
        active: true,
        smartSpeed: 1000,
        autoplay: 6000,
        navText: [
          '<span class="fal fa-angle-left"></span>',
          '<span class="fal fa-angle-right"></span>'
        ],
        responsive: {
          0: {
            items: 1
          },
          600: {
            items: 1
          },
          800: {
            items: 1
          },
          1024: {
            items: 1
          }
        }
      });
    }
  }, []);

  return (
    <section className="banner-section style-one">
      <div className="banner-carousel owl-theme owl-carousel owl-dots-none" ref={carouselRef}>
        {slideImages.map((item, i) => (
          <Slide
            {...item}
            key={`carousel-${i}`}
          />
        ))}
      </div>
    </section>
  );
};

export default Carousel;
