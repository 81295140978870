import React, { useEffect, useState } from "react";
import ApiCalling from "../components/api/ApiCalling";
import { Link } from "react-router-dom";


export default function EventPage(){
  const [data, setData] = useState([])
  useEffect(() => {
    ApiCalling("/tblnotice")
      .then((response) => {
        if (response) {
          setData(response.data);
        }
      })
      .catch((err) => console.error(err));
  });
    return(
        <>
  {/* Page Title */}
  <section
    className="page-title"
    style={{ backgroundImage: "url(assets/images/background/service-bg.jpg)" }}
  >
    <div className="auto-container">
      <div className="content-box">
        <div className="title centred">
          <h1>Events & Notice</h1>
        </div>
        <ul className="bread-crumb clearfix">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>Events</li>
        </ul>
      </div>
    </div>
  </section>
  {/* End Page Title */}
  {/* events-list */}
  <section className="events-list sec-pad-2">
    <div className="auto-container">
      <div className="event-list-content">
      {data.map((d, i) => (
        <div className="schedule-inner" key={`notice_list_${i}`}>
          <div className="schedule-block-three">
            <div className="inner-box" >
              <div className="inner" >
                <div className="schedule-date" >
                  <h2>
                    {/* 26 <span className="year">January’2021</span>
                    <span className="symple">th</span> */}
                    <span className="year flaticon-clock-circular-outline"> {d.evntdate}</span>
                  </h2>
                  <ul className="list clearfix">
                    {/* <li>
                      <i className="flaticon-clock-circular-outline" />
                      12.00 pm - 2.00 pm
                    </li> */}
                    <li>
                      <i className="flaticon-gps" />
                      {d.evntloc}
                    </li>
                  </ul>
                </div>
                <div className="text">
                  <span className="category">
                    <i className="flaticon-star" />
                    {d.posttag}
                  </span>
                  <h3>
                    <Link to="event-details.html">
                      {d.PostTitle}
                    </Link>
                  </h3>
                  <p>
                    {d.PostDetails}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
            ))}
      </div>
    </div>
  </section>
  {/* events-list end */}
</>
)}