import React from "react";
import { Link } from "react-router-dom";

export default function AboutPage() {
  return (
    <>
      {/* Page Title */}
      <section
        className="page-title"
        style={{
          backgroundImage: "url(assets/images/background/service-bg.jpg)",
        }}
      >
        <div className="auto-container">
          <div className="content-box">
            <div className="title centred">
              <h1>About Technology Group Housing Society</h1>
            </div>
            <ul className="bread-crumb clearfix">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>About Us</li>
            </ul>
          </div>
        </div>
      </section>
      {/* End Page Title */}
      {/* about-style-three */}
      <section className="about-style-three">
        <div className="auto-container">
          <div className="row clearfix align-items-center">
            <div className="col-lg-4 col-md-12 col-sm-12 content-column">
              <div className="content_block_5">
                <div className="content-box">
                  <div className="sec-title">
                    <h6>
                      <i className="flaticon-star" />
                      <span>Welcome to TCGHSLTD</span>
                    </h6>
                    <h2>Technology Group Housing Society (Regn No. 515 G/H)</h2>
                    <div className="title-shape" />
                  </div>
                  <div className="text">
                    <h4>TCGHSLTD Was “Founded in 1979”</h4>
                    <p>
                    Technology CGHS LTD is located in IP Extension, a premium location of East Delhi which houses multiple group housing Societies
                    </p>
                    <p>Strategically located with easy access to Delhi NCR and not far from any of the NCR railway stations, Connaught place and premium malls of Noida.</p>
                    <p>Meerut & Noida expressways & rapid metro has significantly reduced the travel times and have facilitated hassle free driving experience and seamless connectivity to other Delhi NCR locations.</p>
                    <p>Akshardham temple itself is just 5 minutes drive from the Society.</p>
                    </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 image-column">
              <figure className="image-box">
                <img src="assets/images/resource/about-2a.jpg" alt="" />
              </figure>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 inner-column">
              <div className="feature-block-one">
                <div className="inner-box">
                  <figure className="image">
                    <img src="assets/images/resource/about-1.jpg" alt="" />
                  </figure>
                  <div className="text">
                    <h4>Council Information</h4>
                      <i className="flaticon-right-arrow" />
                  </div>
                  <div className="overlay-content">
                    <h4>Council Information</h4>
                    <p>
                      Demoralized by the charm some pleasure of the moment so
                      blinded.
                    </p>
                  </div>
                </div>
              </div>
              <div className="feature-block-one">
                <div className="inner-box">
                  <figure className="image">
                    <img src="assets/images/resource/about-3a.jpg" alt="" />
                  </figure>
                  <div className="text">
                    <h4>Latest Annual Plan</h4>
                      <i className="flaticon-right-arrow" />
                  </div>
                  <div className="overlay-content">
                    <h4>Latest Annual Plan</h4>
                    <p>
                      Demoralized by the charm some pleasure of the moment so
                      blinded.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix align-items-center">
          <p>
                      The Technology Co-Operative Group Housing Society Ltd. was
                      formed by a group of 99 young technocrats and
                      professionals, mostly from IIT Kharagpur. The Society was
                      registered with the office of Registrar Co-op Societies,
                      Old Courts Building, Parliament Street, New Delhi-110001
                      on 1st February, 1980 with a registration no. of 515
                      (G/H). Technology Co-Operative Group housing Society Ltd.
                      Comprises of 147 apartments of different sizes and
                      configurations. Technology Co-Operative Group housing
                      Society Ltd. Was allotted a piece of land of 2.45 acres in
                      Patpar Ganj, I. P. Extension (East Delhi) on 6th February
                      1982 and got the physical possession of the plot no. 24
                      (2.454 acres) on 11th October 1984.
                    </p>
                    <p>The Construction work was started in November 1989. The allotment of the flats to the members was done on 3rd January 1993 through draw of lots by RCS and DDA and possession of the first flat was handed over on 25th September 1993. </p>
                    <p>Since then the community & infrastructure has constantly evolved making Technology Apartments one of the best housing complexes in the area facilitating excellent living environment for the families.</p>
          </div>
        </div>
      </section>
      {/* about-style-three end */}
      <section
        className="discover-section sec-pad"
        style={{
          backgroundImage: "url(assets/images/background/service-bg.jpg)",
        }}
      >
        <div className="auto-container">
          <div className="row clearfix">
            <div className="col-lg-12 col-md-6 col-sm-12 discover-block">
              <div
                className="discover-block-one wow fadeInUp animated animated"
                data-wow-delay="00ms"
                data-wow-duration="1500ms"
              >
                <div className="inner-box">
                  <p>
                    Technology Apartments stands out in the area also as a
                    complex with one of the lowest density, thereby providing
                    extensive open &amp; recreational areas for its residents
                  </p>
                  <p>
                    The society has ample parking space for 4 wheeler vehicles
                    of the residents, spread in basement and on surface all
                    along the perimeter. There is also space allocated for guest
                    vehicles. Society also has two gardens, an open gym, two
                    high quality outdoor badminton courts, one basketball court,
                    an indoor games Room.
                  </p>
                  <p>
                    Being sensitive towards environment, Society building is
                    surrounded by dense greenery. Numerous trees, many of which
                    are medicinal or fruit bearing decorate both sides of the
                    internal perimeter wall of the society. The society has a
                    large underground tank for rain water harvesting. While
                    sizable power DG back-up for lifts, pumps, common area
                    lighting and 650 watts for each flat was provided initially,
                    it is now planned to be replaced by PNG Generating sets or
                    Solar Plants. Technology GHS is also the first society to
                    install a composting machine to process entire kitchen waste
                    generated in the society within the society itself,
                    converting it to compost fertilizer. Adequate Fire Safety
                    infrastructure, fully compliant with Delhi Fire Service
                    Guidelines is kept fully operational. Similarly, 24X7
                    professional security &amp; CCTV surveillance is also
                    available for security of the residents.
                  </p>
                  <p>
                    The Society is administered by the elected Governing Body of
                    members and its finances are audited by RCS, Delhi approved
                    auditors.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
