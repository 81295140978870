import React from 'react'

export default function EventPage(){
    return(
        <>
            <div style={{
          textAlign: 'center',
          paddingTop: "50px",
          paddingBottom: "50px"
        }}><h2><b>Content will be published soon on this page</b></h2></div>
        </>)}