import React, { useEffect, useState } from "react";
import ApiCalling from "../components/api/ApiCalling";
import { Link } from "react-router-dom";


export default function ContactPage(){
  const [data, setData] = useState([]);
    
  useEffect(() => {
    ApiCalling("/cntc")
      .then((response) => {
        if (response) {
          setData(response.data);
        }
      })
      .catch((err) => console.error(err));
  });
    return(
            <>
  {/* Page Title */}
  <section
    className="page-title"
    style={{ backgroundImage: "url(assets/images/background/service-bg.jpg)" }}
  >
    <div className="auto-container">
      <div className="content-box">
        <div className="title centred">
          <h1>Contact</h1>
        </div>
        <ul className="bread-crumb clearfix">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>Contact</li>
        </ul>
      </div>
    </div>
  </section>
  {/* End Page Title */}
  {/* contact-information */}
  <section className="contact-information">
    <div className="auto-container">
              {data.map((d, i) => (
      <div className="row clearfix">
        <div className="col-lg-4 col-md-6 col-sm-12 content-column">
          <div className="content_block_12">
            <div className="content-box">
              <div className="sec-title">
                <h6>
                  <i className="flaticon-star" />
                  <span>Make Connecting</span>
                </h6>
                <h2>Contact Information</h2>
                <div className="title-shape" />
              </div>
              <div className="text">
                <p>
                  We understand that it is important for you access our services
                  in a way time.
                </p>
                <h4>
                  <i className="flaticon-map-1" />
                  Technology CGHS LTD <br />
                  Postal Address
                </h4>
                <p>
                  {d.address}
                </p>
                <Link to="/imp-contact">
                  <b>Important Contacts</b>
                  <i className="flaticon-right-arrow" />
                </Link>
              </div>
              <div className="social-box">
                <h4>Social Media</h4>
                <ul className="social-style-one clearfix">
                  <li>
                    <Link to="/">
                      <i className="fab fa-facebook-f" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <i className="fab fa-twitter" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <i className="fab fa-google-plus-g" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <i className="fab fa-instagram" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 image-column">
          <figure className="image-box">
            <img src="assets/images/resource/about-2a.jpg" alt="" />
          </figure>
        </div>
        <div className="col-lg-4 col-md-12 col-sm-12 content-column">
          <div className="content_block_13">
            <div className="content-box">
              <div className="single-item">
                <div className="icon-box">
                  <i className="flaticon-mail-inbox-app" />
                </div>
                <ul className="info clearfix">
                  <li>
                    <h5>Business</h5>
                    <p>
                      <Link to="mailto:tcghsltd@gmail.com">{d.email}</Link>
                    </p>
                  </li>
                </ul>
              </div>
              <div className="single-item">
                <div className="icon-box">
                  <i className="flaticon-emergency-call" />
                </div>
                <ul className="info clearfix">
                  <li>
                    <h5>Phone</h5>
                    <p>
                      <Link to="tel:{d.mobile}">+91{d.mobile}</Link>
                    </p>
                  </li>
                </ul>
              </div>
              <div className="single-item">
                <div className="icon-box">
                  <i className="flaticon-bank" />
                </div>
                <ul className="info clearfix">
                  <li>
                  <h5>Account Details </h5>
                  <p>
                    {d.account}
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
              ))}
    </div>
  </section>
  {/* contact-information end */}

        </>)}