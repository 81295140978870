import { Link } from "react-router-dom";

const Slide = ({ image, title, subtitle, content, btnText }) => {
  return (
    <div className="slide-item">
      <div className="image-layer" style={{ backgroundImage: `url(${image})` }}></div>
      <div className="auto-container">
        <div className="content-box">
          <h6><i className="flaticon-star"></i>{subtitle}</h6>
          <h1>{title}</h1>
          <p>{content}</p>
          <div className="btn-box">
            <Link to="/aboutus" className="theme-btn">
              Read More
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

  export default Slide;