import React, { useEffect, useState } from "react";
import ApiCalling from "../components/api/ApiCalling";
import { Link } from "react-router-dom";


export default function ImpcntctPage() {
  const [data, setData] = useState([]);
    
  useEffect(() => {
    ApiCalling("/impcntc")
      .then((response) => {
        if (response) {
          setData(response.data);
        }
      })
      .catch((err) => console.error(err));
  });

  return (
    <>
      {/* Page Title */}
      <section
        className="page-title"
        style={{
          backgroundImage: "url(assets/images/background/service-bg.jpg)",
        }}
      >
        <div className="auto-container">
          <div className="content-box">
            <div className="title centred">
              <h1>Important Contacts</h1>
            </div>
            <ul className="bread-crumb clearfix">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Important Contacts</li>
            </ul>
          </div>
        </div>
      </section>
      {/* End Page Title */}
      {/* contact-information */}
      <section className="contact-information">
        <div className="auto-container">
          <div className="row clearfix">
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Name</th>
                  <th scope="col">Contact</th>
                </tr>
              </thead>
              <tbody>
              {data.map((d, i) => (
                <tr key={`cntc_{i}`}>
                  <th scope="row">{i+1}</th>
                  <td>{d.name}</td>
                  <td>{d.mobile}</td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>
        </div>
      </section>
      {/* contact-information end */}
    </>
  );
}
