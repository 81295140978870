import React from "react";
// import Slider from "../parts/Slider";
import Carousel from "../components/slideshow/Carousel";
import { Link } from "react-router-dom";

export default function HomePage() {

  return (
    <>
      {/* banner-section */}
      <div className="boxed_wrapper">
        <Carousel />
      </div>
      {/* banner-section end */}
      {/* activities-section */}
      <section className="activities-section centred bg-color-1">
        <div className="auto-container">
          <div className="inner-container">
            <div className="row clearfix">
              <div className="col-lg-3 col-md-6 col-sm-12 single-column">
                <div className="single-item">
                  <div className="icon-box">
                    <i className="flaticon-garbage-truck" />
                  </div>
                  <h6>Schedule</h6>
                  <h4>Garbage &amp; Recycling</h4>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 single-column">
                <div className="single-item">
                  <div className="icon-box">
                    <i className="flaticon-parking" />
                  </div>
                  <h6>In Effect</h6>
                  <h4>Alternate Side Parking</h4>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 single-column">
                <div className="single-item">
                  <div className="icon-box">
                    <i className="flaticon-school" />
                  </div>
                  <h6>Nearby</h6>
                  <h4>Schools and Colleges</h4>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 single-column">
                <div className="single-item">
                  <div className="icon-box">
                    <i className="flaticon-taxes" />
                  </div>
                  <h6>Connectivity</h6>
                  <h4>All Transport Services</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* activities-section end */}
      {/* about-section */}
      <section className="about-section sec-pad bg-color-1">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="col-lg-6 col-md-12 col-sm-12 content-column">
              <div className="content_block_1">
                <div className="content-box">
                  <div className="sec-title">
                    <h6>
                      <i className="flaticon-star" />
                      <span>WELCOME TO TCGHSLTD</span>
                    </h6>
                    <h2>TCGHSLTD is an Technology CGHS LTD</h2>
                    <div className="title-shape" />
                  </div>
                  <div className="text">
                    <h5>
                    Technology CGHS LTD is located in IP Extension, a premium location of East Delhi which houses multiple group housing Societies.
                    </h5>
                    <h4>President, “Rear Adm MK Badhwar”</h4>
                    <p>
                    Technology CGHS LTD is located in IP Extension, a premium location of East Delhi which houses multiple group housing Societies.
                    </p>
                  </div>
                  <div className="inner-box clearfix">
                    <figure className="signature pull-left">
                      <img src="assets/images/icons/signature.png" alt="" />
                    </figure>
                    <ul className="social-style-one clearfix">
                      <li>
                        <Link to="/">
                          <i className="fab fa-facebook-f" />
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <i className="fab fa-twitter" />
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <i className="fab fa-google-plus-g" />
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <i className="fab fa-linkedin-in" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="lower-box">
                    <div className="row clearfix">
                      <div className="col-lg-6 col-md-6 col-sm-12 single-column">
                        <div
                          className="single-item wow fadeInLeft animated animated"
                          data-wow-delay="00ms"
                          data-wow-duration="1500ms"
                        >
                          <div className="icon-box">
                            <i className="flaticon-government" />
                          </div>
                          <h5>Society Office</h5>
                          <p>
                            <Link to="tel:+911147243535">+91 1147243535</Link>
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 single-column">
                        <div
                          className="single-item wow fadeInRight animated animated"
                          data-wow-delay="00ms"
                          data-wow-duration="1500ms"
                        >
                          <div className="icon-box">
                            <i className="flaticon-alert" />
                          </div>
                          <h5>Emergency</h5>
                          <p>
                            <Link to="tel:100">100</Link> (Police, Ambula...)
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 image-column">
              <div className="image_block_1">
                <div className="image-box">
                  <figure className="image">
                    <img src="assets/images/resource/about-1.jpg" alt="" />
                  </figure>
                  <div className="text">
                    <h4>
                      “Best Society across NCR”
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* about-section end */}
      {/* funfact-section */}
      <section className="funfact-section"
          style={{ backgroundImage: "url(assets/images/shape/shape-7.png)" }}
        >
        <div className="auto-container">
          <div className="funfact-content">
            <div className="row clearfix">
              <div className="col-lg-4 col-md-12 col-sm-12 title-column">
                <div className="sec-title">
                  <h6>
                    <i className="flaticon-star" />
                    <span>Interesting Facts</span>
                  </h6>
                  <h2>Society In Numbers</h2>
                  <div className="title-shape" />
                </div>
              </div>
              <div className="col-lg-8 col-md-12 col-sm-12 inner-column">
                <div className="funfact-inner centred">
                  <div className="row clearfix">
                    <div className="col-lg-3 col-md-6 col-sm-12 funfact-block">
                      <div className="funfact-block-one">
                        <div className="inner-box">
                          <div className="count-outer count-box">
                            <span
                              className="count-text"
                              data-speed={1500}
                              data-stop={200}
                            >
                              200
                            </span>
                            <span>+</span>
                          </div>
                          <h6>Residents</h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 funfact-block">
                      <div className="funfact-block-one">
                        <div className="inner-box">
                          <div className="count-outer count-box">
                            <span
                              className="count-text"
                              data-speed={1500}
                              data-stop={10}
                            >
                              40
                            </span>
                            <span>+</span>
                          </div>
                          <h6>Families</h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 funfact-block">
                      <div className="funfact-block-one">
                        <div className="inner-box">
                          <div className="count-outer count-box">
                            <span
                              className="count-text"
                              data-speed={60}
                              data-stop={40}
                            >
                            40
                            </span>
                            <span>acre</span>
                          </div>
                          <h6>Area</h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 funfact-block">
                      <div className="funfact-block-one">
                        <div className="inner-box">
                          <div className="count-outer count-box">
                            <span
                              className="count-text"
                              data-speed={1500}
                              data-stop={5}
                            >
                              5
                            </span>
                            <span>/</span>
                          </div>
                          <h6>Award win</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* funfact-section end */}
      

    </>
  );
}
