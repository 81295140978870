import React from "react";
import { GalleryData } from "./data/GalleryData";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
export default function GalleryPage() {
  const [data, setData] = useState([]);
  const [collection, setCollection] = useState([]);

  useEffect(() => {
    setData(GalleryData);
    setCollection([...new Set(GalleryData.map((item) => item.titile))]);
  }, []);

  const gallery_filter = (itemData) => {
    const filterData = GalleryData.filter((item) => item.titile === itemData);
    setData(filterData);
  };

  return (
    <>
      <section
        className="page-title"
        style={{
          backgroundImage: "url(assets/images/background/service-bg.jpg)",
        }}
      >
        <div className="auto-container">
          <div className="content-box">
            <div className="title centred">
              <h1>Gallery</h1>
            </div>
            <ul className="bread-crumb clearfix">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Gallery</li>
            </ul>
          </div>
        </div>
      </section>
      {/* End Page Title */}
      {/* portfolio-section */}
      <section className="portfolio-section sec-pad">
        <div className="auto-container">
          <div className="sec-title centred">
            <h6>
              <i className="flaticon-star" />
              <span>OUR GALLERY</span>
              <i className="flaticon-star" />
            </h6>
            <h2>What We Have Done</h2>
            <div className="title-shape" />
          </div>
          <div className="sortable-masonry">
            <div className="filters">
              <ul className="filter-tabs filter-btns centred clearfix">
                <li
                  className="active filter"
                  data-role="button"
                  data-filter=".all"
                >
                  <button onClick={() => setData(GalleryData)}>
                    [ View All ]
                  </button>
                  {collection.map((item) => (
                    <li>
                      <button
                        onClick={() => {
                          gallery_filter(item);
                        }}
                      >
                        {item}
                      </button>
                    </li>
                  ))}
                </li>
              </ul>
            </div>
            <div className="items-container row clearfix">
              {data.map((item) => (
                <div className="col-lg-3 col-md-6 col-sm-12 masonry-item small-column all guide conference celebration">
                  <div className="portfolio-block-one">
                    <div className="inner-box">
                      <figure className="image-box" key={item.id}>
                          <img src={item.image} alt={item.caption} id={item.id} />
                      </figure>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      {/* portfolio-section end */}
    </>
  );
}
