import HeaderComponent from "./components/HeaderComponent";
import FooterComponent from "./components/FooterComponent";
import { Route, Routes } from "react-router-dom";
import { createContext } from "react";
import { ToastContainer } from 'react-toastify';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import McrwaPage from './pages/McrwaPage';
import ContactPage from './pages/ContactPage';
import GalleryPage from './pages/GalleryPage';
import ImpcntctPage from "./pages/ImpcntctPage";
import DocPage from "./pages/DocPage";
import FaqPage from "./pages/FaqPage";
import EventPage from "./pages/EventPage";
import DownloadPage from "./pages/DownloadPage";
// import Carousel from './components/slideshow/Carousel';


export const webInfo = createContext({});
function App() {
  return (
      <webInfo.Provider >
        <ToastContainer />
        <HeaderComponent />

        <main>
        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/aboutus' element={<AboutPage />} />
          <Route path='/mcrwa' element={<McrwaPage />} />
          <Route path='/contact' element={<ContactPage />} />
          <Route path='/gallery' element={<GalleryPage />} />
          <Route path='/imp-contact' element={<ImpcntctPage />} />
          <Route path='/document-page' element={<DocPage />} />
          <Route path='/faq-page' element={<FaqPage />} />
          <Route path='/event-page' element={<EventPage />} />
          <Route path='/download-page' element={<DownloadPage />} />

          {/* <Route path='/donation' element={<DonationPage />} /> */}
          {/* <Route path='*' element={<Error404Page />} /> */}

        </Routes>
</main>
        <FooterComponent />
      </webInfo.Provider>
  );
}

export default App;
