import React, { useEffect, useState } from "react";
import ApiCalling from "../components/api/ApiCalling";
import { Link } from "react-router-dom";

export default function McrwaPage() {
    const [data, setData] = useState([]);
    
    useEffect(() => {
      ApiCalling("/mcrwa")
        .then((response) => {
          if (response) {
            setData(response.data);
          }
        })
        .catch((err) => console.error(err));
    });

  return (
    <>
      {/* Page Title */}
      <section
        className="page-title"
        style={{
          backgroundImage: "url(assets/images/background/service-bg.jpg)",
        }}
      >
        <div className="auto-container">
          <div className="content-box">
            <div className="title centred">
              <h1>MC(RWA)</h1>
            </div>
            <ul className="bread-crumb clearfix">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>MC(RWA)</li>
            </ul>
          </div>
        </div>
      </section>
      {/* End Page Title */}
      {/* contact-information */}
      <section className="contact-information">
        <div className="auto-container">
        <div className="title centred"><b>
            Current Managing Committee Members and their Duties
            </b> </div>
          <div className="row clearfix">
          
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Name</th>
                  <th scope="col">Post</th>
                  <th scope="col">Mobile</th>
                  <th scope="col">Duties</th>
                </tr>
              </thead>
              <tbody>
              {data.map((d, i) => (
                <tr key={`mcrwa_{i}`}>
                  <th scope="row">{i+1}</th>
                  <td>{d.name}</td>
                  <td>{d.post}</td>
                  <td>{d.mobile}</td>
                  <td>{d.duties}</td>
                </tr>
                
              ))}
              </tbody>
            </table>
          </div>
        </div>
      </section>
      {/* contact-information end */}
    </>
  );
}
